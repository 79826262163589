/**
 * Page styles.
 */

html,
body {
  min-height: 100%;
  height: auto;
}

@media (min-width: @screen-xlg-min) {
  
  .container {
    width: @container-xlg;
  }
}

.region-header-top {
  background: @brand-warning;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  display: none;
}

.node-type-landing-page {
  
  .navbar {
    margin-bottom: 0;
    position: absolute;
    width: 100%;
  }
}

.navbar-header {
  width: 100%;
}

.container {
  
  > .navbar-header {
    margin-left: 0;
    margin-right: 0;
  }
}

.logo-image {
  max-height: 85px;
  width: auto;
  margin: 0 auto;
}

.header__site-slogan {
  display: none;
  .header__site-slogan();
}

@media (min-width: @screen-sm-min) {
  
  .region-header-top {
    display: block;
  }
  
  .logo-image {
    float: left;
    max-height: 125px;
    margin: 0;
  }
  
  .logo-center {
    
    .logo-image {
      float: none;
      margin: 0 auto;
    }
  }
}

@media all and (min-width: 800px) {
  
  .header__site-slogan {
    display: block;
  }
}

@media (min-width: @screen-md-min) {
  
  .logo-image {
    max-height: none;
  }
  
  .header__site-slogan {
    .header__site-slogan--large();
  }
}

.main-wrapper {
  background: #fff;
  padding-bottom: 30px;
}

.main-wrapper-trans {
  
  .main-wrapper {
    background: transparent;
  }
}

.node-type-landing-page {
  
  .main-wrapper {
    padding-bottom: 0;
  }
}

/**
 * Footer styles.
 */

.footer {
  background: @brand-primary;
  border: none;
  color: #fff;
  font-size: @font-size-xsmall;
  margin-top: 0;
  padding-top: @line-height-computed;
  padding-bottom: @line-height-computed;
  text-transform: uppercase;
  text-align: center;
}

@media (min-width: @screen-sm-min) {
  
  .footer {
    padding-top: @line-height-computed*3;
    padding-bottom: @line-height-computed*3;
  }
  
  .footer__inner {
    padding-left: @line-height-computed*3;
    padding-right: @line-height-computed*3;
  }
}