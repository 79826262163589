/**
 * Navigation styles.
 */

.btn-login {
  background: fade(#ffffff, 60%);
  border-radius: 15px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  
  &:hover {
    background: fade(@brand-primary, 90%) !important;
  }
}

.jumbotron {
  
  .btn-login {
    background: none !important;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
  }
}

.region-header-top {
  
  .nav {
    
    > li {
      
      > a.btn-login {
        display: inline-block;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}

.wld-country-menu ul.menu li ul {
  margin: 0;
}

/**
 * Footer menu.
 */

.footer__menu {
  .clearfix();
  padding: 0;
  margin: 0;
  
  li {
    margin-bottom: @line-height-computed/2;
    
    &.last {
      margin-bottom: 0;
    }
  }
  
  a {
    color: #fff;
  }
}

@media (min-width: @screen-sm-min) {
  
  .footer__menu {
    
    li {
      //float: left;
      display: inline-block;
      margin-bottom: 0;
      margin-right: @line-height-computed/2;
    }
  }
}