/**
 * Mobile message paragraph styles.
 */

.mobile-message {
  padding-top: 15%;
  padding-bottom: 15%;
  position: relative;
  min-height: 500px;
}

.mobile-message__message {
  position: relative;
  z-index: 1000;
  background: fade(#fff, 90%) url(../images/woman-icon-uk.png) @line-height-computed @line-height-computed*2 no-repeat;
  background-size: 60px auto;
  //padding: @line-height-computed*2 @line-height-computed*2 @line-height-computed*2 @line-height-computed+70;
  padding: @line-height-computed*2 @line-height-computed @line-height-computed*2 @line-height-computed+60+@line-height-computed;
  margin-bottom: @line-height-computed*2;
}

.mobile-message__heading {
  margin-top: 0;
}

.mobile-message__subheading {
  color: @gray-light;
  margin-bottom: @line-height-computed;
}

.mobile-message__link {
  font-size: @font-size-h4;
  padding-top: @line-height-computed/2;
  
  a {
    color: @brand-warning;
  }
}

.mobile-message__sign-up {
  display: block;
  margin: 0 auto;
  width: 250px;
  text-transform: uppercase;
  font-size: @font-size-h4;
  font-family: @font-family-raleway;
  border-radius: 30px;
  position: relative;
  z-index: 1001;
}

.mobile-message__phone {
  background: url(../images/social-hand.png) -50px 0 no-repeat;
  background-size: auto 500px;
  height: 500px;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 480px) {
  
  .mobile-message__sign-up {
    width: 450px;
    font-size: @font-size-h2;
  }
  
  .mobile-message__phone {
    background-position: right 0;
  }
}

@media (min-width: @screen-md-min) {
  
  .mobile-message__message {
    max-width: 500px;
    background-color: #fff;
  }
  
  .mobile-message__sign-up {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    width: 550px;
  }
}

@media (min-width: @screen-lg-min) {
  
  .mobile-message {
    min-height: 690px;
  }
  
  .mobile-message__phone {
    background-size: auto 650px;
    height: 650px;
  }
}