/**
 * Form styles.
 */

label {
  font-weight: normal;
}

.terms-conditions {
  color: @text-color;
  
  a {
    color: @text-color;
  }
}

.wld-flexslider-join-form {
  
  .panel {
    background: transparent;
    .box-shadow(none);
    width: 380px;
  }
  
  .panel-body {
    padding: 0 30px;
  }
  
  .form-item {
    position: relative;
  }
  
  label {
    display: block;
    text-align: center;
  }
  
  label.error {
    top: 90px;
    font-size: 11px;
  }
  
  .next-button,
  .form-submit {
    .btn();
    .btn-primary();
    width: 150px;
    margin: 0 auto;
    display: block;
  }
  
  .form-control {
    height: auto;
  }
  
  .form-inline {
    .clearfix();
    
    .form-group {
      float: left;
    }
    
    .form-control {
      display: block;
      width: 100%;
    }
  }
  
  .form-item-birthday-dob-day {
    width: 25%;
    padding-right: 5px;
  }
  
  .form-item-birthday-dob-month {
    width: 50%;
    padding-right: 5px;
  }
  
  .form-item-birthday-dob-year {
    width: 25%;
  }
}

/**
 * Double form.
 */

.block-wld-double-form {
  
  .wld-flexslider-join-form {
    
    .next-button,
    .form-submit {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.custom-nav {
  text-align: center;
  margin: 2em 0 1em 0;
  
  li {
    width: 10px;
    height: 10px;
    margin: 0 2px !important;
    text-indent: -10000px;
    display: inline-block;
    background: @step-default;
    cursor: pointer;
    
    &.slide-nav-active {
      background: @step-active;
    }
  }
}

/**
 * Jumbotron forms.
 */

.jumbotron {
  
  .wld-flexslider-join-form {
    font-family: @font-family-raleway;
    
    .flex-viewport {
      min-height: 0;
    }
    
    .panel-body {
      padding: 0;
    }
    
    .form-group {
      margin-bottom: 0;
    }
    
    .form-item {
      .clearfix();
    }
    
    label {
      float: left;
      width: 50%;
      background: @brand-warning;
      height: @input-height-large;
      line-height: @input-height-large;
      color: #fff;
      text-transform: uppercase;
      font-size: @font-size-h3;
    }
    
    .form-control {
      float: left;
      width: 50%;
      height: @input-height-large;
      font-size: @font-size-h3;
      border: none;
      background-color: fade(#ffffff, 90%);
      -moz-appearance: none;
      -webkit-appearance: none;
      font-family: @font-family-raleway;
      
      &:focus {
        border: none;
        .box-shadow(none);
      }
    }
    
    .form-inline {
      float: left;
      width: 50%;
      
      .form-control {
        width: 100%;
      }
    }
    
    .form-item-birthday-dob-day {
      width: 28%;
    }
    
    .form-item-birthday-dob-month {
      width: 45%;
    }
    
    .form-item-birthday-dob-year {
      width: 27%;
    }
    
    .form-select {
      .select-arrow();
    }
    
    .next-button,
    .form-submit {
      width: 100%;
      text-transform: uppercase;
      font-size: @font-size-h3;
    }
  }
  
  .custom-nav {
    
    li {
      margin: 0 !important;
      width: 20%;
      height: 5px;
      background: transparent;
      
      
      &.slide-nav-active {
        background: @brand-warning;
      }
    }
  }
  
  .form-error-message {
    width: 100%;
    text-align: center;
    font-size: 12px;
    position: absolute;
    bottom: -25px;
    left: 0;
    
    .error {
      background: transparent;
      color: @brand-warning;
    }
  }
  
  // Expandable form.
  .wld-expandable-join-form {
    font-family: @font-family-raleway;
    
    .form-group {
      .clearfix();
    }
    
    label {
      background: fade(@brand-warning, 80%);
      color: #fff;
      padding: 0 5px 0 10px;
      margin-bottom: 0;
      float: left;
      width: 15%;
      height: @form-item-height-small;
      line-height: @form-item-height-small;
    }
    
    .form-control {
      background: fade(#fff, 90%);
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      height: @form-item-height-small;
    }
    
    .form-select {
      .select-arrow();
      float: left;
      width: 85%;
    }
    
    .form-inline {
      width: 85%;
      float: left;
      
      .form-item {
        padding-right: 0;
      }
    }
    
    .btn-primary {
      background-color: fade(@brand-primary, 80%);
      border: none;
    }
    
    .terms-conditions {
      color: #fff;
      
      a {
        color: #fff;
      }
    }
  }
}

/**
 * Expandable join form.
 */

.wld-expandable-join-form {
  font-size: @form-font-size-small;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  
  label {
    display: block;
    text-align: left;
  }
  
  .form-control {
    background: fade(#fff, 90%);
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: @form-font-size-small;
    padding: 6px 10px;
    height: @form-item-height-small;
  }
  
  .form-select {
    .select-arrow();
  }
  
  .form-group {
    margin-bottom: 7px;
  }
  
  .form-inline {
    .clearfix();
    
    .form-item {
      float: left;
    }
    
    .form-control {
      width: 100%;
    }
    
    .form-group {
      margin-bottom: 0;
    }
  }
  
  .form-item-dob-day {
    width: 30%;
    padding-right: 5px;
  }
  
  .form-item-dob-month {
    width: 40%;
    padding-right: 5px;
  }
  
  .form-item-dob-year {
    width: 30%;
  }
  
  .btn {
    margin-top: 10px;
    width: 150px;
  }
  
  .terms-conditions {
    //top: 0;
    margin: 30px 0 0 0;
    font-size: 8px;
    //display: none;
  }
  
  &.full-expandable-form {
    color: @text-color;
    
    .form-group {
      margin-bottom: 10px;
    }
    
    label {
      display: none;
    }
    
    .form-item-dob {
      .clearfix;
      
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  
  /*Placeholder Styling*/
  ::-webkit-input-placeholder {
    color: @text-color;
    -webkit-font-smoothing: antialiased;
    opacity: 1;
  }
  
  ::-moz-placeholder {
    color: @text-color;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: @text-color;
    opacity: 1;
  }
  
  input:-moz-placeholder {
    color: @text-color;
    opacity: 1;
  }
}

.join-form-expanded {
  
  .wld-expandable-join-form {
    
    .terms-conditions {
      display: block;
    }
  }
}

@media (min-width: 480px) {
  
  .wld-expandable-join-form {
    
    &.full-expandable-form {
      
      .form-group {
        //margin-bottom: 15px;
      }
    
      .form-item-dob {
        background: #fff;
        border-radius: @input-border-radius;
        border: 1px solid @input-border;
        height: @form-item-height-small;
        
        label {
          display: block;
          float: left;
          width: 20%;
          font-weight: normal;
          padding: 0 10px;
          box-sizing: border-box;
          line-height: @form-item-height-small;
          margin-bottom: 0;
        }
        
        .form-control {
          height: @form-item-height-small - 2;
        }
        
        .form-inline {
          width: 80%;
          float: right;
          box-sizing: border-box;
          
          .form-control {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media all and (min-width: 600px) {
  
  .node-type-photo-bg-lander {
    
    .wld-expandable-join-form {
      
      .form-item-dob {
        
        .form-item {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  
  .wld-expandable-join-form {
    font-size: @form-font-size-large;
    
    .form-control {
      height: @form-item-height-large;
      font-size: @form-font-size-large;
    }
    
    .form-item-dob-day {
      width: 25%;
    }
    
    .form-item-dob-month {
      width: 50%;
    }
    
    .form-item-dob-year {
      width: 25%;
    }
    
    &.full-expandable-form {
      
      .form-item-dob {
        height: @form-item-height-large;
        
        label {
          line-height: @form-item-height-large;
        }
        
        .form-control {
          height: @form-item-height-large - 2;
        }
      }
    }
  }
}

/**
 * WLD GDPR Five Step Form
 */

.wld-button {
  background-color: @brand-primary;
}

.wld-form__suffix {
  margin-top: 15px;
}
