/**
 * Jumbotron styles.
 */

.jumbotron {
  background: #fff url(../images/blonde-hero.jpg) -275px 0 no-repeat;
  background-size: cover;
  margin-bottom: 0;
  padding-top: 90px;
  
  h1 {
    .heading-white();
    font-size: 30px;
    text-align: center;
  }
  
  .block-wld-join-form {
    max-width: 400px;
    margin: 0 auto 20px auto;
  }
  
  .block-wld-flexslider-form {
    display: none;
    width: 90%;
    margin: 50px auto 100px auto;
    background: fade(#ffffff, 60%);
    padding: 5px 5px 0 5px;
    position: relative;
    
    .terms-conditions {
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 100%;
      font-size: 10px;
      text-align: center;
      color: #f8f8f8;
      
      a {
        color: #f8f8f8;
      }
    }
  }

  .block-wld-forms-wld-forms-five-step {
    
    .wld-form__fields {
      background: fade(#ffffff, 60%);
      padding: 5px;
      margin-bottom: 0;
    }
    
    .wld-form__actions {
      background: fade(#ffffff, 60%);
      padding: 0 5px 5px 5px;
    }
    
    .wld-form__suffix {
      color: #fff;
      
      a {
        color: #fff;
      }
    }
  }
}

.bbw-domain {
  
  .jumbotron {
    background-image: url(../images/bbw-hero.jpg);
  }
}

.milf-domain {
  
  .jumbotron {
    background-image: url(../images/milf-hero.jpg);
    background-position: right top;
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  
  .milf-domain {
    
    .jumbotron {
    
      .block-wld-join-form {
        margin-top: 150px;
      }
    }
  }
}

@media (min-width: 480px) {
  
  .jumbotron {
    background-position: -100px 0;
    
    h1 {
      font-size: 48px;
    }
  }
  
  .bbw-domain {
    
    .jumbotron {
      background-position: -225px 0;
    }
  }
  
  .milf-domain {
    
    .jumbotron {
      background-position: -450px 0;
    }
  }
}

@media (min-width: 600px) {
  
  .milf-domain {
    
    .jumbotron {
      background-position: right 0;
    }
  }
}

@media (min-width: @screen-sm-min) {
  
  .jumbotron {
    padding-top: 150px;
    background-position: 0 0;
    
    .block-wld-join-form {
      display: none; 
    }
    
    .block-wld-flexslider-form {
      display: block;
    }
    
    .block-menu-block,
    .login-link-block {
      display: none;
    }
  }
  
  .bbw-domain {
    
    .jumbotron {
      position: relative;
      background-position: -200px 0;
      min-height: 600px;
      
      .block-wld-flexslider-form {
        position: absolute;
        top: 360px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  
  .jumbotron {
    padding-top: 200px;
    
    h1 {
      font-size: 60px;
    }
    
    .block-wld-flexslider-form {
      width: 800px;
    }
    
    .block-wld-forms-wld-forms-five-step {
      margin-top: 50px;
    }
  }
  
  .bbw-domain {
    
    .jumbotron {
      
      .block-wld-flexslider-form {
        top: 450px;
      }
    }
  }
}

@media (min-width: @screen-lg-min) {
  
  .jumbotron {
    
    .block-wld-flexslider-form {
      width: 900px;
      margin-top: 100px;
    }
  }
  
  .bbw-domain {
    
    .jumbotron {
      background-position: 0 0;
    }
  }
}

@media (min-width: @screen-lg-min) and (min-height: 800px) {
  
  .bbw-domain {
    
    .jumbotron {
      
      .block-wld-flexslider-form {
        top: 525px;
      }
    }
  }
}