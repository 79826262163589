/**
 * Blocks styles.
 */

/**
 * Header top region blocks.
 */

.region-header-top {
  
  .block {
    float: right;
    margin-left: 15px;
  }
  
  .wld-country-menu {
    position: relative;
    width: 37px;
    height: 22px;
    
    .menu-block-wrapper {
      position: absolute;
      top: 4px;
      z-index: 1001;
    }
  }
}

/**
 * Member images.
 */

.wld-member-image-block__content {
  border: 10px solid #fff;
}

.wld_badge_item {
  float: left;
  width: 25%;
  
  &:nth-child(5) {
    clear: left;
  }
}

.wld_badge_item_photo img  {
  height: auto;
  width: 100%;
	display:block;
}

@media (min-width: @screen-sm-min) {
  
  .wld-member-image-block__content {
    border: 20px solid #fff;
  }
  
  .wld_badge_item {
    width: 12.5%;
    
    &:nth-child(5) {
      clear: none;
    }
  }
}

/**
 * Secondary sidebar blocks.
 */
.region-sidebar-second {
  
  .block {
    margin: 30px 0;
  }
}

@media (min-width: @screen-md-min) {
  .region-sidebar-second {
  
    .block {
      margin-top: 0;
    }
  }  
}

/**
 * Footer blocks.
 */
.footer {
  
  .block {
    margin-bottom: @line-height-computed;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}