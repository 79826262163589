/**
 * Benefits box paragraph styles.
 */

.benefits-box {
  background-position: 0 0;
  background-repeat: no-repeat;
  padding: 20px;
  color: #fff;
}

.benefits-box--phone {
  background-image: url(../images/bb-phone.jpg);
}

.benefits-box--woman {
  background-image: url(../images/bb-woman.jpg);
}

.benefits-box--headset {
  background-image: url(../images/bb-headset.jpg);
}

.benefits-box--laptop {
  background-image: url(../images/bb-laptop.jpg);
}

.benefits-box__text-wrap {
  margin-bottom: 40px;
}

.benefits-box__heading {
  .heading-white();
  font-size: 48px;
  line-height: 55px;
  margin-bottom: 20px;
}

.benefits-box__button {
  background: #fff;
  border-radius: 15px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: @brand-warning;
  border: none;
  width: 100%;
  max-width: 250px;
  
  &:hover {
    cursor: default;
    background: #fff;
    color: @brand-warning;
  }
}

.benefits-box__mem-image {
  
  img {
    margin: 0 auto;
  }
}

@media (min-width: @screen-sm-min) {
  
  .benefits-box {
    margin-bottom: -99979px;
    padding-bottom: 99999px;
  }
}

@media (min-width: @screen-lg-min) {
  
  .benefits-box {
    min-height: 400px;
    margin-bottom: -99899px;
    padding-top: 100px;
  }
  
  .benefits-box__text-wrap {
    margin-bottom: 0;
  }
  
  .benefits-box__members-wrap {
    padding-top: 100px;
  }
}
