/**
 * Landing page styles.
 */

.landing_page__benefits {
  
  &.row {
    overflow: hidden;
  }
}

.landing_page__welcome {
  padding: 15% 0;
  background: url(../images/flag-bg-uk.jpg) center center no-repeat;
  background-size: cover;
}

.domain-country-australia {
  
  .landing_page__welcome {
    background-image: url(../images/flag-bg-aus.jpg);
  }
}

.landing_page__welcome-content {
  background: #fff;
  max-width: 600px;
  margin: 0 auto;
}

.landing_page__welcome-image {
  overflow: hidden;
  
  &[class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

.landing_page__welcome-message {
  &[class*='col-'] {
    padding: @line-height-computed @line-height-computed*2;
  }
}

.landing_page__welcome-subheading {
  color: @gray-light;
}

.landing_page__welcome-link {
  font-size: @font-size-h4;
  padding-top: @line-height-computed/2;
  
  a {
    color: @brand-warning;
  }
}

.landing_page__members {
  background: url(../images/members-bg.jpg) center center no-repeat;
  background-size: cover;
  padding: 10% 0;
  
  .wld-member-image-block {
    width: 90%;
    margin: 0 auto;
  }
}

.landing_page__testimonials {
  background: @brand-primary;
  padding-top: 10%;
  padding-bottom: 10%;
}

.landing_page__testimonials-inner {
  max-width: @container-lg;
  overflow: hidden;
}

.landing_page__blog {
  padding-top: 30px;
  border-bottom: 20px solid #f8f8f8;
}

.landing_page__mobile-message {
  background: url(../images/couple.jpg) 0 0 no-repeat;
  background-size: cover;
}

.bbw-domain {
  
  .landing_page__mobile-message {
    background-image: url(../images/bbw-woman-bg.jpg);
    background-position: right top;
  }
}

@media (min-width: @screen-md-min) {
  
  .landing_page__welcome-content {
    max-width: @container-sm;
  }
  
  .landing_page__members {
    
    .wld-member-image-block {
      width: 70%;
    }
  }
}

@media (min-width: @screen-lg-min) {
  
  .landing_page__welcome-image {
    
    .img-responsive {
      width: 110%;
      max-width: 110%;
      margin-left: -10%;
    }
  }
  
  .landing_page__welcome-content {
    max-width: @container-lg;
  }
}
