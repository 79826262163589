/**
 * Testimonial paragraph styles.
 */

.testimonial {
  margin-bottom: @line-height-computed*2;
}

.testimonial__image {
  background-color: @brand-warning;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 200px;
  min-height: 200px;
}

.testimonial--woman {
  
  .testimonial__image {
    background-image: url(../images/testimonial-woman.png);
  }
}

.testimonial--man {
  
  .testimonial__image {
    background-image: url(../images/testimonial-man.png);
  }
}

.testimonial--woman-aus {
  
  .testimonial__image {
    background-image: url(../images/testimonial-woman-aus.png);
  }
}

.testimonial--man-aus {
  
  .testimonial__image {
    background-image: url(../images/testimonial-man-aus.png);
  }
}

.testimonial--woman-bbw {
  
  .testimonial__image {
    background-image: url(../images/testimonial-woman-bbw.png);
  }
}

.testimonial--man-bbw {
  
  .testimonial__image {
    background-image: url(../images/testimonial-man-bbw.png);
  }
}

.testimonial--woman-milf {
  
  .testimonial__image {
    background-image: url(../images/testimonial-woman-milf.png);
  }
}

.testimonial--man-milf {
  
  .testimonial__image {
    background-image: url(../images/testimonial-man-milf.png);
  }
}

.testimonial__content {
  background: #fff;
  padding: 40px;
}

.testimonial__heading {
  margin-top: 0;
}

.testimonial__name {
  color: @gray-light;
  font-family: @font-family-raleway;
  margin-bottom: @line-height-computed;
}

@media (min-width: @screen-sm-min) {
  
  .testimonial {
    overflow: hidden;
  }
  
  .testimonial__image {
    min-height: 50px;
    width: 30%;
    float: left;
    margin-bottom: -10000px;
    padding-bottom: 10000px;
    background-size: 85% auto;
  }
  
  .testimonial__content {
    width: 70%;
    float: right;
    margin-bottom: -9950px;
    padding: 40px 40px 10000px 40px;
  }
}

@media (min-width: @screen-md-min) {
  
  .testimonial {
    margin-bottom: 0;
    overflow: visible;
  }
  
  .testimonial__image {
    width: 40%;
    background-size: auto;
  }
  
  .testimonial__content {
    width: 60%;
  }
}
