/**
 * Text row paragraph styles.
 */

.text-row {
  padding: 30px;
  
  h2,
  h3,
  h4 {
  .heading-white();
  }
  
  h2 {
    margin-top: 0;
    font-size: 48px;
    line-height: 55px;
    margin-bottom: 20px;
  }
  
  p {
    max-width: 800px;
    margin: 0 auto 30px auto;
  }
  
  .btn {
    text-transform: uppercase;
    font-size: @font-size-h4;
    font-family: @font-family-raleway;
    border-radius: 30px;
  }
}

.domain-country-uk {
  
  .text-row {
    
    &.bg-red {
      background-image: url(../images/uk-flag-red.jpg);
      background-position: center center;
      background-size: cover;
    }
  }
}

.domain-country-australia {
  
  .text-row {
    
    &.bg-red {
      background-image: url(../images/aus-flag-red.jpg);
      background-position: center center;
      background-size: cover;
    }
  }
}