/**
 * Helper classes.
 */

.bg-red {
  background-color: @brand-warning;
}

.bg-blue {
  background-color: @brand-primary;
}

.text-white {
  color: #fff;
  
  h1,
  h2,
  h3,
  h4 {
    color: #fff;
  }
}

.btn-white {
  background-color: #fff;
  
  &:hover {
    color: @brand-primary;
    background-color: #f8f8f8;
  }
}