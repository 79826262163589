/**
 * Mobile Carousel Lander styles.
 */

.node-type-mobile-carousel-lander {
  
  .block-wld-join-form {
    padding-bottom: 20px;
  }
}

.view-mcl-slider {
  
  .flexslider {
    background-image: url(../images/join-free-corner.png);
  }
}
