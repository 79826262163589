/**
 * Blog entry nodes.
 */

@media (min-width: @screen-md-min) {
  
  .blog-entry {
    padding-bottom: 30px;
  }
}

@media (min-width: 600px) {
  
  .blog-entry {
    
    &.lead-image-left {
      
      .blog-entry__lead-image {
        float: left;
        width: 40%;
        margin-right: 30px;
      }
    }
    
    &.lead-image-right {
      
      .blog-entry__lead-image {
        float: right;
        width: 40%;
        margin-left: 30px;
      }
    }
    
    &.view-mode-home_page {
      padding-bottom: 0;
      
      .blog-listing__image {
        margin-bottom: 0;
      }
    }
  }
}

.blog-entry__date {
  display: block;
  margin-bottom: 30px;
}

.blog-entry__lead-image,
.blog-entry__body {
  margin-bottom: 30px;
}

/**
 * Blog listing page styles.
 */

.blog-listing-row {
  border-bottom: 2px solid @brand-primary;
  padding-bottom: 30px;
  margin-bottom: 30px;
  
  &:last-child {
    border-bottom: none;
  }
}

.blog-listing__title {
  line-height: 1.2em;
  margin-bottom: 5px;
}

.blog-listing__date {
  display: block;
  margin-bottom: 30px;
}

.blog-listing__image {
  margin-bottom: 30px;
}

.blog-listing__body {
  margin-bottom: 30px;
}